import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { DefaultImage } from "~/old-app/constants";

const CardCategoryGrid = ({ item, parent }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <div className="w-20">
      <div className="relative flex flex-col items-center justify-center min-w-0 break-words w-full">
        <Link
          to={`/${language}/categories/${item.slug || item.id}`}
          className={`flex h-24 w-24`}
        >
          <img
            alt="..."
            src={item?.cover || parent?.cover}
            className="w-full h-full object-contain rounded-lg"
            loading="lazy"
            onError={(e) => {
              e.target.src = parent?.cover ? parent?.cover : DefaultImage;
            }}
          />
        </Link>

        <blockquote className="relative py-4 pb-0">
          <h2 className="text-sm font-medium text-secondary-950 text-center leading-3 line-clamp-2">
            {item?.name}
          </h2>
        </blockquote>
      </div>
    </div>
  );
};

export default CardCategoryGrid;
